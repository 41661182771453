'use client';

import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import z from 'zod';

export interface FooterNewsletterSubscribeForm {
    title?: string;
    buttonLabel?: string;
    formUrl: string;
}

export const FooterNewsletterSubscribeForm = ({ title = 'Sign up to receive our deals, news and inspo.', buttonLabel = 'Subscribe', formUrl }: FooterNewsletterSubscribeForm) => (
    <>
        <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
            {title}
        </Typography>
        <FormContainer
            defaultValues={{ email: '' }}
            resolver={zodResolver(subscriptionFormValuesSchema)}
            onSuccess={(data) => {
                const url = new URL(formUrl);
                url.searchParams.append('MERGE0', data.email);
                window.location.href = url.toString();
            }}
        >
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                <TextFieldElement name="email" label="Email address" size="small" required />
                <Button type="submit" variant="contained" sx={{ height: 40, fontWeight: 600 }}>
                    {buttonLabel}
                </Button>
            </Stack>
        </FormContainer>
    </>
);

const subscriptionFormValuesSchema = z.object({
    email: z.string().email(),
});

import Link from '@mui/material/Link';
import { SocialIcon } from '@/components/SociaIcon';
import { CmsSocialNetwork } from '@/services/cms/models/CmsSocialNetwork';

export interface SocialLinkProps {
    socialNetwork: CmsSocialNetwork;
}

export const SocialLink = ({ socialNetwork }: SocialLinkProps) => {
    if (socialNetwork.link?.url) {
        return (
            <Link
                href={socialNetwork.link.url}
                title={socialNetwork.link.title}
                sx={{ display: 'flex', alignItems: 'center' }}
                target={socialNetwork.link.openInNew ? '_blank' : undefined}
            >
                <SocialIcon socialNetwork={socialNetwork} />
            </Link>
        );
    }

    return <SocialIcon socialNetwork={socialNetwork} />;
};

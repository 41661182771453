'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FocusImage } from '@/components/FocusImage';
import WebsiteSearchForm from '@/components/SearchForm/WebsiteSearchForm';
import { SiteContainer } from '@/components/SiteContainer';
import { CmsPage } from '@/services/cms/pages/CmsPage';
import { headingFontSizes } from '@/styles/theme';


export interface PageTitleProps {
    pageData: CmsPage;
}

export const PageTitle = ({ pageData: { title, showTitle, bannerImage, layout, bookingWidget } }: PageTitleProps) => {
    if (!title || !showTitle || layout==='Blog') {
        return null;
    }

    if (bannerImage?.link) {
        return (
            <>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: layout === 'BookingWidgetTitleBar' ? '' : 'center',
                        mb: 6,
                        position: 'relative',
                        ...(layout !== 'BookingWidgetTitleBar'
                            ? {
                                  height: `${theme.settings.bannerHeights.small}px`,
                                  flex: `0 0 ${theme.settings.bannerHeights.small}px`,
                              }
                            : undefined),
                    })}
                >
                    <FocusImage
                        priority={true}
                        sx={(theme) => ({
                            height: `${theme.settings.bannerHeights.small}px`,
                            position: 'absolute',
                            zIndex: -1,
                        })}
                        {...bannerImage}
                    />
                    <SiteContainer>
                        <Typography
                            variant="title1"
                            component="h1"
                            sx={{
                                color: 'white',
                                ...headingFontSizes.h1,
                                textShadow: '0 0 0.5rem rgba(0,0,0,.85)',
                                mb: 0,
                                pt: layout === 'BookingWidgetTitleBar' ? 5 : 0,
                                pb: layout === 'BookingWidgetTitleBar' ? 1 : 0,
                                // fontSize: 48,
                            }}
                        >
                            {title}
                        </Typography>
                        {layout === 'BookingWidgetTitleBar' ? (
                            <Card
                                sx={(theme) => ({
                                    top: theme.settings.appBar.spacer(parseInt(theme.spacing(2))),
                                    maxWidth: 704,
                                })}
                            >
                                <CardContent>
                                    <WebsiteSearchForm fleetType={bookingWidget?.fleetTypeSlug} country={bookingWidget?.regionCode} location={bookingWidget?.branchCode} />
                                </CardContent>
                            </Card>
                        ) : null}
                    </SiteContainer>
                </Box>
            </>
        );
    }

    return (
        <SiteContainer>
            <Typography variant="title1" component="h1"
                        sx={(theme) => ({
                            fontFamily: theme.typography.fontFamilyBold,
                            fontWeight: 700,
                            lineHeight: 1.2,
                            marginBottom: '.5rem',
                            marginTop: '0',
                            ...headingFontSizes.h6
                        })}

            >
                {title}
            </Typography>
        </SiteContainer>
    );
};

import {Image, ImageProps} from '@/components/Image';
import {prefixCmsUrl} from '@/lib/prefixCmsUrl';
import {CmsImageData as CmsImageData} from '@/services/cms/models/CmsImageData';
import {Theme} from '@mui/material';
import {SxProps} from '@mui/system/styleFunctionSx';
import React from 'react';

export interface CmsImageProps extends CmsImageData {
    sx?: SxProps<Theme>;
    loading?: ImageProps['loading'];
    style?: ImageProps['style'];
    sizes?: ImageProps['sizes'];
    quality?: number | `${number}`;
    priority?: boolean;
    placeholder?: ImageProps['placeholder'];
    blurDataURL?: string;
    fill?: boolean;
}

export const CmsImage = React.forwardRef<HTMLImageElement, CmsImageProps>(({ sx, ...props }, ref) => (
    <Image
        ref={ref}
        src={prefixCmsUrl(props.link)}
        alt={props.title || props.name}
        width={props.width}
        height={props.width}
        sx={sx}
        loading={props.loading}
        style={props.style}
        sizes={props.sizes}
        quality={props.quality}
        priority={props.priority}
        placeholder={props.placeholder}
        blurDataURL={props.blurDataURL}
        fill={props.fill}
    />
));
CmsImage.displayName = 'CmsImage';

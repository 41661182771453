import React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export const InstagramIcon = ({ viewBox, ...props }: SvgIconProps) => (
    <SvgIcon viewBox={viewBox || '0 0 100 100'} {...props}>
        <circle style={{ fill: 'currentColor' }} cx="50" cy="50" r="50" />
        <path
            style={{ fill: '#fff' }}
            d="M71.1 33.38c-.01-2.27-1.83-3.94-4.28-3.93-2.62.01-4.48 1.59-4.5 3.81-.02 2.08 2.22 4.44 4.22 4.43 2.4-.01 4.58-2.06 4.56-4.31m-20.63.79c-4.08.05-7.31.99-10.16 3.04-4.66 3.35-6.58 8.04-6.4 13.58.12 3.72.78 7.29 3.79 10.03 3.43 3.13 7.36 5.14 12.05 5.34 5.52.24 9.86-2.07 13.17-6.39 4-5.22 4.48-12.43 1.02-17.99-3.2-5.13-8.04-7.45-13.47-7.61M23.66 47.34c.22-2.71.55-7.35.97-11.98.24-2.69.79-5.28 3.04-7.16 1.53-1.29 3.26-2.19 5.16-2.81 4.88-1.6 9.93-1.91 15.02-1.92 5.69-.01 11.35.46 16.97 1.27 5.18.75 8.38 3.88 10.05 8.67a27.49 27.49 0 0 1 1.52 9.62c-.11 5.13-.44 10.25-.73 15.38-.13 2.29-.25 4.59-.57 6.86-.84 5.95-3.41 8.7-9.35 10.18-4.06 1.01-8.19 1.36-12.36 1.34-6.21-.02-12.37-.54-18.47-1.69-1.9-.36-3.7-1.01-5.33-2.06-2.87-1.85-3.98-4.71-4.63-7.85-1.08-5.26-1.33-10.58-1.29-17.85"
        />
        <path
            style={{ fill: '#fff' }}
            d="M50.58 61.02c-3.76-.28-7.01-2.48-9.16-6.21-2.04-3.55-1.92-7.29.4-10.55 3.15-4.44 8.36-6.34 13.54-1.94 2.76 2.34 4.41 5.32 4.8 8.93.53 5.14-3.67 9.75-9.58 9.77"
        />
    </SvgIcon>
);

import React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export const FacebookIcon = ({ viewBox, ...props }: SvgIconProps) => (
    <SvgIcon viewBox={viewBox || '0 0 100 100'} {...props}>
        <circle style={{ fill: 'currentColor' }} cx="50" cy="50" r="50" />
        <path
            style={{ fill: '#fff' }}
            d="M54.61 40.85c2.34.17 4.61.34 6.88.51.57.04 1.15.08 1.72.13.89.07 1.16.8 1.37 1.46.24.77.41 1.6.45 2.41.07 1.66.05 3.32.03 4.98 0 .34-.16.67-.27 1-.12.36-.28.71-.38 1.08-.17.6-.56.85-1.18.86-2.41.02-4.81.06-7.21.09-.39 0-.78.04-1.17 0-.59-.05-.77.17-.77.77 0 3.56-.05 7.11-.05 10.67.01 4.69.05 9.37.08 14.06 0 .91-.07.89-.91 1.16-1.74.55-3.53.5-5.31.5-1.64.01-3.28-.08-4.91-.18-.22-.01-.57-.32-.61-.53-.25-1.47-.46-2.96-.64-4.44-.58-4.78-.49-9.6-.59-14.4-.05-2.38.02-4.77.08-7.15.01-.62-.21-.8-.8-.85-2.14-.18-4.29-.4-6.43-.63-.81-.08-1.62-1.04-1.62-2.1-.01-2.48 0-4.97.12-7.45.06-1.36.47-1.62 1.83-1.71 2.21-.15 4.42-.31 6.63-.5.16-.01.42-.26.44-.43.11-.83.14-1.68.22-2.51.3-3.19.64-6.37 1.97-9.34 2.42-5.39 6.42-8.9 12.2-10.26 2.86-.68 5.74-.56 8.61.04 1.07.22 1.73 1.15 1.71 2.23-.05 2.8-.47 5.55-1.01 8.29-.13.64-.49.9-1.17.93-1.06.05-2.15 0-3.16.25-2 .5-3.26 1.96-4.2 3.73-1.13 2.14-1.58 4.47-1.95 6.82-.04.16-.01.3 0 .51"
        />
    </SvgIcon>
);

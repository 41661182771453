import { Breakpoint, LinkProps, createTheme } from '@mui/material';
import { createThemeOptionsForBrand, defaultAppBarSettings, mapBreakPoints } from '@jucy-ui/appearance';
import { env } from '@/env';
import {boldenVan, poppins, poppinsBold, poppinsRegular} from '@/styles/fonts';
import { RouteLink } from './RouteLink';

const isTest = env.NEXT_PUBLIC_ENV === 'test';

const topBarHeights: Record<Breakpoint, number> = {
    xs: 0,
    sm: 37,
    md: 37,
    lg: 37,
    xl: 37,
    xxl: 37,
};

const testBannerHeight = 46;
const themeOptions = createThemeOptionsForBrand('jucy', {
    typography: {
        fontFamily: `${poppins.style.fontFamily}, 'Verdana', 'sans-serif'`,
        fontFamilyRegular: `${poppinsRegular.style.fontFamily}, 'Verdana', 'sans-serif'`,
        fontFamilyBold: `${poppinsBold.style.fontFamily}, 'Verdana', 'sans-serif'`,
        alternate: {
            fontFamily: `${boldenVan.style.fontFamily}, 'Verdana', 'sans-serif'`,
        },
        title1: {
            fontFamily: `${poppinsBold.style.fontFamily}, 'Verdana', 'sans-serif'`,
            fontWeight: 600,
            fontSize: '27px',
        },
        subtitle1: {
            fontFamily: `${poppinsRegular.style.fontFamily}, 'Verdana', 'sans-serif'`,
            fontWeight: 700,
            fontSize: '14px',
        },
        subtitle2: {
            fontFamily: `${poppinsRegular.style.fontFamily}, 'Verdana', 'sans-serif'`,
            fontWeight: 600,
            fontSize: '18px',
        },
    },
    settings: {
        appBar: {
            testBannerHeight,
            spacer: (padding = 0) =>
                mapBreakPoints(defaultAppBarSettings.heights, (height, breakPoint) => {
                    const result = height + topBarHeights[breakPoint] + padding + (isTest ? testBannerHeight : 0);
                    return `${result}px`;
                }),
        },
        bannerHeights: {
            large: 640,
            medium: 332,
            small: 248,
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: RouteLink,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: RouteLink,
            },
        },
    },
    palette: {
        background: {
            light: '#f2f2f2',
            dark: '#e0e0e0',
        },
    },
});
export const headingFontSizes = {
    h1: {
        fontSize: 'calc(1.425rem + 2.1vw)',
        '@media (min-width: 1200px)': {
            fontSize: '3rem',
        },
    },
    h2: {
        fontSize:'calc(1.29375rem + .525vw)',
        '@media (min-width: 1200px)': {
            fontSize: '1.6875rem',
        },
    },
    h3: {
        fontSize: 'calc(1.3rem + .6vw)',
        '@media (min-width: 1200px)': {
            fontSize: '1.75rem',
        },
    },
    h4: {
        fontSize: 'calc(1.275rem + .3vw)',
        '@media (min-width: 1200px)': {
            fontSize: '1.5rem',
        },
    },
    h5: {
        fontSize: 'calc(1.2625rem + .15vw)',
        '@media (min-width: 1200px)': {
            fontSize: '1.375rem' ,
        },
    },
    h6: {
        fontSize: '1.125rem'
    }
}
export const theme = createTheme(themeOptions);

'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { RegionMenu } from '@/components/Menus/RegionMenu';
import { NavBarContainer } from '@/components/NavBars/NavBarContainer';
import { NavBarDivider } from '@/components/NavBars/NavBarDivider';
import { PlainButton } from '@/components/PlainButton';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsLink } from '@/services/cms/models/CmsLink';

export interface BottomNavBarProps {
    agentLoginLink?: CmsLink;
    sitemapLink?: CmsLink;
    tnCLink?: CmsLink;
    privacyPolicyLink?: CmsLink;
}

export const BottomNavBar = ({ agentLoginLink, tnCLink, privacyPolicyLink, sitemapLink }: BottomNavBarProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return (
        <NavBarContainer sx={{ p: { xs: 1, sm: 'unset' } }}>
            <Stack direction="row" gap={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <PlainButton href="mailto:res@jucyworld.com">Email Us</PlainButton>
                <NavBarDivider />
                <RegionMenu id="region-select-footer" />
                {agentLoginLink ? (
                    <>
                        <NavBarDivider />
                        <PlainButton href={addSiteLinkPrefix(agentLoginLink.url)} target={agentLoginLink.openInNew ? '_blank' : undefined}>
                            {agentLoginLink.title}
                        </PlainButton>
                    </>
                ) : null}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 1 }} sx={{ alignItems: 'center', margin: { xs: 'auto', sm: 'unset' } }}>
                <Box sx={{ pt: 1, pb: { xs: 0, sm: 1 } }}>© Travvia. All rights reserved.</Box>
                <Stack
                    direction="row"
                    gap={0}
                    sx={{
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        '&> *:not(:last-child)': {
                            '&:after': {
                                content: '"\\2022"',
                                display: 'block',
                                mx: 0.5,
                            },
                        },
                    }}
                >
                    {[tnCLink, sitemapLink, privacyPolicyLink].filter(Boolean).map((link) => (
                        <PlainButton key={link.id} href={addSiteLinkPrefix(link.url)} target={link?.openInNew ? '_blank' : undefined}>
                            {link.title}
                        </PlainButton>
                    ))}
                </Stack>
            </Stack>
        </NavBarContainer>
    );
};

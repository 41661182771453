import React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export const LinkedInIcon = ({ viewBox, ...props }: SvgIconProps) => (
    <SvgIcon viewBox={viewBox || '0 0 100 100'} {...props}>
        <circle style={{ fill: 'currentColor' }} cx="50" cy="50" r="50" />
        <path
            style={{ fill: '#fff' }}
            d="M41.7 43.47c-.07-.82-.18-1.63-.31-2.44-.11-.71-.16-1.54-.64-2.11-.85-1.04-2.61-.95-3.81-.98-1.73-.05-3.45.09-5.15.37-.21.03-.31.17-.33.32-1.91 1.16-2.13 4.13-2.28 6.13-.23 3.08.11 6.17.21 9.25.1 3.17.03 6.33-.04 9.5-.03 1.58-.07 3.15-.08 4.73 0 1.31.06 2.66.68 3.84 1.32 2.51 4.22 2.35 6.41 1.16 1.12-.61 2.3-1.35 2.97-2.48.69-1.18.93-2.63 1.16-3.96.51-2.96.6-5.97.67-8.96.07-3.08.25-6.11.53-9.18.16-1.73.16-3.46.01-5.19m-.55-16.74c-.26-1.55-.7-2.98-1.99-3.97-1.33-1.03-3.02-1.33-4.67-1.35-1.05-.01-3.16-.19-3.73.99-1.07.93-2.16 1.94-2.71 3.28-.56 1.36-.48 3.01-.25 4.44.23 1.36.81 2.7 1.9 3.58 1.15.94 2.69 1.39 4.15 1.55 2.88.3 6.27-.81 7.19-3.8.47-1.53.37-3.16.11-4.72m35.57 29.61c.18-4.15.96-8.57-.2-12.64-.43-1.5-1.16-2.95-2.3-4.04-1.35-1.29-3.07-1.86-4.88-2.15-2.48-.39-5.16-.42-7.59.31-1.07.32-2.12.78-3.06 1.38-.44.28-.86.6-1.24.96.15-1.19.25-2.52-.94-3.09-.59-.28-1.29-.41-1.93-.52-.94-.17-1.9-.21-2.85-.11-1.37.15-2.72.71-3.98 1.27-.79.36-2.21 1.09-1.79 2.19v.01a.35.35 0 0 0 0 .3c.57 1.54.67 3.24.87 4.85.25 1.98.41 3.99.55 5.98.28 4.05.02 8.11-.26 12.16-.13 1.89-.13 3.85-.43 5.72-.21 1.31-.63 2.85.45 3.9 1.98 1.91 5.26 1.48 7.65.75 1.1-.34 2.46-.74 3.05-1.82.36-.66.54-1.49.7-2.22.19-.85.19-1.67.05-2.53-.16-.93-.11-4.76-.14-5.74-.06-1.97.03-3.94.22-5.91.18-1.85-.04-3.71.66-5.44.5-1.25 1.54-3.4 3.14-3.36 1.41.04 2.49 1.29 3.01 2.48.54 1.23.73 2.61.86 3.94.35 3.58.34 7.14.16 10.73-.1 1.97-.15 3.93-.22 5.9-.05 1.39-.14 3.06.76 4.23.4.51.92.73 1.55.81.04 0 .07-.01.1-.01.52.34 1.2.44 1.78.6 1.06.31 2.13.42 3.18.05.8-.28 1.76-.63 2.33-1.28.58-.66.64-1.49.69-2.33.13-2.58.32-5.17.27-7.76-.02-1.26-.16-2.51-.22-3.77-.08-1.25-.06-2.53 0-3.8"
        />
    </SvgIcon>
);

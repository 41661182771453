'use client';

import * as React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { usePathname } from 'next/navigation';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsBreadcrumbLink } from '@/services/cms/models/CmsBreadcrumbLink';

export interface BreadcrumbsProps {
    breadcrumbs?: CmsBreadcrumbLink[];
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    const currentPathname = usePathname();
    if (!breadcrumbs?.length) {
        return null;
    }
    return (
        <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs.map((breadcrumb, i) => {
                const link = addSiteLinkPrefix(breadcrumb.link);
                const current = link === currentPathname;
                return (
                    <BreadcrumbItem
                        key={i}
                        current={current}
                        breadcrumb={{
                            ...breadcrumb,
                            link: link,
                        }}
                    />
                );
            })}
        </MuiBreadcrumbs>
    );
};

interface BreadcrumbItemProps {
    breadcrumb: CmsBreadcrumbLink;
    current: boolean;
}

const BreadcrumbItem = ({ breadcrumb, current }: BreadcrumbItemProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    if (current) {
        return (
            <Typography
                variant="caption"
                sx={{
                    py: 1.37,
                    display: 'inline-block',
                }}
            >
                {breadcrumb.title}
            </Typography>
        );
    }
    return (
        <Link
            underline="hover"
            sx={{
                typography: 'caption',
                py: 1.37,
                display: 'inline-block',
            }}
            color="primary"
            href={addSiteLinkPrefix(breadcrumb.link)}
        >
            {breadcrumb.title}
        </Link>
    );
};

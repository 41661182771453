'use client';

import {headingFontSizes} from '@/styles/theme';
import HtmlToReact, { Parser } from 'html-to-react';
import React, { CSSProperties, HTMLAttributeReferrerPolicy, useCallback, useMemo } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Element } from 'domhandler';
import Image from 'next/image';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { prefixCmsUrl } from '@/lib/prefixCmsUrl';


type HtmlToReactProcessingInstruction = {
    replaceChildren?: boolean;
    shouldProcessNode: (node: Element) => boolean;
    processNode: (node: Element, children: React.ReactNode[], index: number) => React.ReactNode;
};

export interface CmsHtmlContentProps {
    html?: string | null;
    plain?: boolean;
    rules?: HtmlToReactProcessingInstruction[];
}

export const CmsHtmlContent = ({ html, rules, plain }: CmsHtmlContentProps) => {
    const processingInstructions = useHtmlToReactProcessingInstructions();
    const children = useMemo(
        () => Parser().parseWithInstructions(html?.replace(/>\s+</g, '><')?.trim() || '', () => true, [...(rules || []), ...processingInstructions]),
        [html, processingInstructions, rules]
    );
    return <Box sx={plain ? undefined : cmsHtmlStyles}>{children}</Box>;
};

const useResolveUrl = () => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return useCallback(
        (link: string) => {
            if (!link) {
                return '#';
            }
            if (!link.startsWith('/')) {
                try {
                    //Next.js prefetcher crashes the site if there is an illegal url in hrefs...
                    //CMS editors could potentially crash the site if a bad url is used
                    //So we try to create a new URL to check if it is a valid url
                    new URL(link);
                } catch (e) {
                    return '#';
                }
            }
            return addSiteLinkPrefix(link);
        },
        [addSiteLinkPrefix]
    );
};

const useHtmlToReactProcessingInstructions = (): HtmlToReactProcessingInstruction[] => {
    const resolveUrl = useResolveUrl();
    return useMemo(
        () => [
            {
                shouldProcessNode: (node) => node.name === 'a',
                processNode: (node, children, index) => {
                    if (node.attribs.class?.includes('btn')) {
                        const size = node.attribs.class.includes('btn-lg') ? 'large' : 'medium';
                        return <Button
                            sx={{
                                px: 1.5,
                                fontWeight: 'bold',
                                ...(size === 'large' && {
                                    fontSize: '1.15rem',
                                    lineHeight: '1.75rem',
                                    py: 2,
                                    px: 5
                                })
                            }}
                            size={size}
                            variant="contained"
                            key={index} href={resolveUrl(node.attribs.href)}>{children[0]}</Button>;
                    }

                    return (
                        <Link key={index} href={resolveUrl(node.attribs.href)} className={node.attribs.class}>
                            {children[0]}
                        </Link>
                    );
                }
            },
            {
                shouldProcessNode: (node) => node.name === 'iframe',
                processNode: (node: Element) => {
                    const { referrerpolicy, frameborder, allowfullscreen, style, ...props } = node.attribs || {};
                    return (
                        <iframe
                            {...props}
                            style={convertStyleToJSX(style)}
                            referrerPolicy={(referrerpolicy || 'no-referrer') as HTMLAttributeReferrerPolicy}
                            allowFullScreen={typeof allowfullscreen !== 'undefined'}
                        />
                    );
                },
            },
            {
                shouldProcessNode: (node) => node.name === 'img',
                processNode: (node, children, index) => (
                    <Image
                        key={index}
                        src={prefixCmsUrl(node.attribs.src)}
                        className={node.attribs.class}
                        width={node.attribs.width ? parseInt(node.attribs.width) : undefined}
                        height={node.attribs.height ? parseInt(node.attribs.height) : undefined}
                        alt={node.attribs.alt}
                        title={node.attribs.title}
                    />
                ),
            },
            {
                shouldProcessNode: () => true,
                processNode: HtmlToReact.ProcessNodeDefinitions().processDefaultNode,
            },
        ],
        [resolveUrl]
    );
};
const convertStyleToJSX = (style?: string) => {
    if (!style) {
        return undefined;
    }

    return style.split(';').reduce((styleObject, line) => {
        const [property, value] = line.split(':').map((part) => part.trim());
        if (!property || !value) {
            return styleObject;
        }

        const camelCaseProperty = property.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        return { ...styleObject, [camelCaseProperty]: value } as CSSProperties;
    }, {} as CSSProperties);
};
const cmsHtmlStyles = (theme: Theme) => ({
    typography: 'body1',
    '& img': {
        maxWidth: '100%',
        height: 'auto',
        '&.left': {
            float: 'left',
            maxWidth: '50%',
            margin: '5px 20px 10px 0',
        },
        '&.right': {
            float: 'right',
            maxWidth: '50%',
            margin: '5px 0 10px 20px',
        },
        '&.leftAlone': {
            float: 'left',
            marginRight: '100%',
            marginBottom: '10px',
            clear: 'both',
        },
        '&.rightAlone': {
            float: 'right',
            marginLeft: '100%',
            marginBottom: '10px',
            clear: 'both',
        },
        '&.center': {
            float: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            marginBottom: '10px',
            clear: 'both',
        },
    },
    '& .text-jucy-green': {
        '&, & a': {
            color: 'secondary.main',
        },
    },
    '& .text-center': {
        textAlign: 'center',
    },
    '& .text-uppercase': {
        textTransform: 'uppercase',
    },
    '& .font-weight-heavy': {
        fontFamily: theme.typography.fontFamilyRegular,
    },

    '& .embed': {
        position: 'relative',
        width: '100%!important',
        '&:before': {
            content: '""',
            display: 'block',
            paddingTop: '57.037%',
        },
        '& iframe': {
            inset: 0,
            border: 0,
            height: '100%',
            position: 'absolute',
            width: '100%',
        },
    },
    '& .h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6': {
        fontFamily: theme.typography.fontFamilyRegular,
        fontWeight: 600,
        lineHeight: 1.2,
        marginBottom: '.5rem',
        marginTop: '0',
    },
    '& .h1,h1': headingFontSizes.h1,
    '& .h2,h2': headingFontSizes.h2,
    '& .h3,h3': headingFontSizes.h3,
    '& .h4,h4': headingFontSizes.h4,
    '& .h5,h5': headingFontSizes.h5,
    '& .h6,h6': headingFontSizes.h6,
    '& .font-apex': {
        fontFamily: theme.typography.alternate.fontFamily,
        fontWeight: 'normal',
        '&, & strong': {
            fontWeight: 'normal',
        },
    },
});

import React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export const YoutubeIcon = ({ viewBox, ...props }: SvgIconProps) => (
    <SvgIcon viewBox={viewBox || '0 0 100 100'} {...props}>
        <circle style={{ fill: 'currentColor' }} cx="50" cy="50" r="50" />
        <path
            style={{ fill: '#fff' }}
            d="M43.22 38.3c.84 6.73.01 13.38-.21 20.19.52-.37.92-.66 1.34-.93 1.16-.74 2.35-1.44 3.5-2.2 2.35-1.55 4.69-3.11 7.02-4.69 1.63-1.1 3.24-2.23 4.86-3.35.06-.04.16-.03.24-.04-5.53-3.34-11.04-6.33-16.75-8.98m36.8 2.13v2.85c-.04.24-.1.48-.1.72-.09 4.06-.18 8.12-.26 12.18-.06 2.87-.15 5.73-1.03 8.49-.5 1.58-1.25 2.99-2.98 3.54-.66.21-1.36.38-2.05.43-3.12.22-6.23.43-9.35.57-3.56.16-7.13.31-10.69.34-5.33.06-10.66.07-15.99 0-2.51-.03-5.03 0-7.51-.54-2.15-.46-4.29-.86-5.98-2.48-1.09-1.05-1.79-2.28-1.97-3.74-.16-1.27-.1-2.57-.12-3.86-.06-2.98-.22-5.96-.14-8.93.09-3.45.36-6.9.62-10.34.17-2.3.31-4.62 1.11-6.82.74-2.04 2.01-3.53 4.23-4.06 1.33-.32 2.66-.32 4.01-.25 1.68.08 3.37.21 5.06.16 1.58-.04 3.16-.31 4.74-.42 1.89-.13 3.79-.29 5.68-.29 7.95-.01 15.9.04 23.85.08.3 0 .62.04.89.15.51.21.99.51 1.5.73 1.15.5 2.21 1.1 3.13 1.99 1.82 1.75 2.63 3.94 3.01 6.34.14 1.06.22 2.11.34 3.16"
        />
    </SvgIcon>
);

'use client';

import * as React from 'react';
import { Breadcrumbs } from '@/components/Pages/components/Breadcrumbs';
import { PageTitle } from '@/components/Pages/components/PageTitle';
import { SiteContainer } from '@/components/SiteContainer';
import { CmsPage } from '@/services/cms/pages/CmsPage';

export interface PageHeaderProps {
    pageData: CmsPage;
    headerSpacer?: boolean;
}

export const PageHeader = ({ pageData, headerSpacer = true }: PageHeaderProps) => (
    <>
        <SiteContainer sx={headerSpacer ? (theme) => ({ paddingTop: theme.settings?.appBar.spacer() }) : undefined}>
            {!pageData.hideBreadcrumbs ? <Breadcrumbs breadcrumbs={pageData.breadcrumbs} /> : null}
        </SiteContainer>
        {pageData.showTitle && pageData.title ? <PageTitle pageData={pageData} /> : null}
    </>
);

import { SvgIconProps } from '@mui/material';
import { FacebookIcon } from '@/components/Icons/Social/FacebookIcon';
import { InstagramIcon } from '@/components/Icons/Social/InstagramIcon';
import { LinkedInIcon } from '@/components/Icons/Social/LinkedInIcon';
import { YoutubeIcon } from '@/components/Icons/Social/YoutubeIcon';
import { CmsSocialNetwork } from '@/services/cms/models/CmsSocialNetwork';

const socialIconProps: SvgIconProps = {
    color: 'primary',
    sx: {
        fontSize: {
            xs: '50px',
            sm: '35px',
        },
    },
};

export interface SocialIconProps {
    socialNetwork: CmsSocialNetwork;
}

export const SocialIcon = ({ socialNetwork }: SocialIconProps) => {
    if (socialNetwork.type === 'Facebook') {
        return <FacebookIcon {...socialIconProps} />;
    }

    if (socialNetwork.type === 'LinkedIn') {
        return <LinkedInIcon {...socialIconProps} />;
    }

    if (socialNetwork.type === 'Youtube') {
        return <YoutubeIcon {...socialIconProps} />;
    }
    if (socialNetwork.type === 'Instagram') {
        return <InstagramIcon {...socialIconProps} />;
    }
    return <>{socialNetwork.type}</>;
};

const normalizeSrc = src => {
    return src.startsWith('/') ? src.slice(1) : src;
};

export default function cloudflareLoader({src, width, quality, format}) {
    const params = [`width=${width}`, `format=${format || 'auto'}`];
    if (quality) {
        params.push(`quality=${quality}`);
    }
    const paramsString = params.join(',');
    return `https://www.jucy.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { CmsImage, CmsImageProps } from '@/components/CmsImage';
import { ImageProps } from '@/components/Image';


export const FocusImage = ({ sx, ...props }: CmsImageProps) => {
    const focusPointX = props?.focusPoint?.percentageX;
    const focusPointY = props?.focusPoint?.percentageY;
    const style: ImageProps['style'] =
        focusPointX && focusPointY
            ? {
                  objectFit: 'cover',
                  objectPosition: focusPointX && focusPointY ? `${focusPointX}% ${focusPointY}%` : '50% 50%',
                  height: '100%',
                  width: '100%',
              }
            : undefined;
    return props?.link ? <CmsImage {...props} sx={mergeSx(style, sx)} /> : null;
};
